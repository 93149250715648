import { trackTemplate } from '../../../utils/tracking';

/**
 * Gallery click
 * @param string text
 */
export function imageGalleryClick(text, type) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Clicks',
      action: type === 'imageGallery' ? 'Image Gallery' : 'ImageGallery',
      label: text,
      value: '0',
    },
  });
}
