import key from 'weak-key';
import { useSelector } from 'react-redux';

// types
import { ImageGalleryItem } from '../content-elements-payload';

// components
import { BUTTON_ALIGNMENT, BUTTON_BACKGROUND, BUTTON_COLORS, Button } from '../../Button/Button';
import { Translation } from '../../Translation/Translation';
import ThumbnailLandingpage from './thumbnail-landingpage';
import ThumbnailDefault from './thumbnail-default';

// utils
import { useIsMobile } from '../../App/SizeProvider';
import { currentPageTypeSelector } from '../../../utils/selectors/pageSelectors';
import { imageGalleryClick } from './trackingActions';
import { useTracking } from '../../../utils/hooks/useTracking';

interface ThumbnailProps {
  imageGallery: ImageGalleryItem[];
  maxLength: number;
  setMaxLength: (maxLength: number) => void;
  isSectionReference: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setCurrentItemIndex: (currentItemIndex: number) => void;
}

export default function Thumbnail({
  imageGallery,
  maxLength,
  setMaxLength,
  isSectionReference,
  setIsOpen,
  setCurrentItemIndex,
}: ThumbnailProps) {
  const isMobile = useIsMobile();
  const pageType = useSelector(currentPageTypeSelector);
  const track = useTracking();

  const handleLoadMore = () => {
    if (maxLength < imageGallery.length - 3) {
      setMaxLength(maxLength + 3);
    } else if (maxLength < imageGallery.length) {
      setMaxLength(imageGallery.length);
    }
  };

  const images: React.JSX.Element[] = [];
  const imageGalleryItems = isMobile ? imageGallery.slice(0, maxLength) : imageGallery;

  imageGalleryItems.forEach((image, index) => {
    const Thumbnail = pageType === 'landingpage' ? ThumbnailLandingpage : ThumbnailDefault;
    const imageAltInfo = image.pictureAlt ? ` / ${image.pictureAlt}` : '';
    const trackingInfo = `${image.description}${imageAltInfo}`;
    images.push(
      <Thumbnail
        key={key(image)}
        image={image}
        onClick={() => {
          track.trackEvent(imageGalleryClick(trackingInfo, 'imageGallery'));
          setIsOpen(true);
          setCurrentItemIndex(index);
        }}
        index={index}
        isSectionReference={isSectionReference}
      />,
    );
  });

  if (imageGalleryItems.length !== imageGallery.length) {
    images.push(
      <div className="c-image-gallery__item" key="load_more">
        <Button
          key="button"
          color={BUTTON_COLORS.WHITE}
          background={BUTTON_BACKGROUND.PRIMARY}
          symbol="reload"
          align={BUTTON_ALIGNMENT.CENTER}
          onClick={handleLoadMore}
        >
          <Translation id="web20_more_results" key="translation" />
        </Button>
      </div>,
    );
  }
  return images;
}
