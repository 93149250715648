// types
import { ImageGalleryItem } from '../content-elements-payload';

// components
import { Title } from '../Title/Title';
import { TitleFormats } from '../Title/title.types';
import GalleryLightbox from './gallery-lightbox';
import Thumbnail from './thumbnail';

export interface ImageGalleryDefaultProps {
  imageGallery: ImageGalleryItem[];
  title: string;
  subtitle: string;
  imagesPerRow: string;
  border: boolean | string;
  imgResolutionsPicture: string;
  isSectionReference: boolean;
  isChild: boolean;
  catalogName: string;
  maxLength: number;
  setMaxLength: (maxLength: number) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  currentItemIndex: number;
  setCurrentItemIndex: (currentItemIndex: number) => void;
  sectionId?: string;
}

export function ImageGalleryDefault({
  imagesPerRow,
  imgResolutionsPicture,
  sectionId,
  border,
  title,
  subtitle,
  isChild,
  isSectionReference,
  imageGallery,
  catalogName,
  maxLength,
  setMaxLength,
  isOpen,
  setIsOpen,
  currentItemIndex,
  setCurrentItemIndex,
}: Readonly<ImageGalleryDefaultProps>) {
  const borderStyle = border === 'disable-border' ? ` c-image-gallery--${border}` : '';

  let galleryType = 'c-image-gallery ';
  galleryType += imagesPerRow === '' ? 'c-image-gallery--4' : `c-image-gallery--${imagesPerRow}`;
  galleryType += `-${imgResolutionsPicture}`;

  return (
    <div className="grid-container" id={sectionId}>
      <Title
        title={title}
        subtitle={subtitle}
        Format={isChild ? TitleFormats.h4 : TitleFormats.h2}
        titlePreviewId="#st_title"
        subtitlePreviewId="#st_subtitle"
        isSectionReference={isSectionReference}
        formatClassName="h3"
      />
      <div
        className={galleryType + borderStyle}
        data-preview-id={catalogName && !isSectionReference ? `#${catalogName}` : undefined}
      >
        {imageGallery.length > 0 && (
          <Thumbnail
            imageGallery={imageGallery}
            maxLength={maxLength}
            setMaxLength={setMaxLength}
            isSectionReference={isSectionReference}
            setIsOpen={setIsOpen}
            setCurrentItemIndex={setCurrentItemIndex}
          />
        )}
      </div>
      {isOpen && (
        <GalleryLightbox
          imageGallery={imageGallery}
          maxLength={maxLength}
          currentItemIndex={currentItemIndex}
          setCurrentItemIndex={setCurrentItemIndex}
          setIsOpen={setIsOpen}
        />
      )}
    </div>
  );
}
