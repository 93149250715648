import { useState } from 'react';
import { useSelector } from 'react-redux';

// types
import type { ImageGalleryItem } from '../content-elements-payload';

// utils
import { currentPageTypeSelector } from 'utils/selectors/pageSelectors';
import { useSectionId } from 'utils/hooks/use-section-id';
import { ImageGalleryLandingpage } from './image-gallery-landingpage';
import { ImageGalleryDefault } from './image-gallery-default';
import { isEmpty } from 'utils/is-empty';

export interface ImageGalleryProps {
  imageGallery: ImageGalleryItem[];
  title: string;
  anchor: string;
  subtitle: string;
  imagesPerRow: string;
  imgResolutionsPicture: string;
  pageType: string;
  border: boolean | string;
  contentIndex: number;
  titleInAnchor: boolean;
  catalogName: string;
  isSectionReference: boolean;
  isChild?: boolean;
}

export function ImageGallery({
  imageGallery,
  title,
  anchor,
  subtitle,
  border,
  imagesPerRow,
  imgResolutionsPicture,
  contentIndex,
  titleInAnchor,
  catalogName,
  isSectionReference,
  isChild = false,
}: Readonly<ImageGalleryProps>) {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [maxLength, setMaxLength] = useState(3);
  const sectionId = useSectionId(title, anchor, titleInAnchor, contentIndex);
  const pageType = useSelector(currentPageTypeSelector);

  if (isEmpty(imageGallery)) {
    return null;
  }

  if (pageType === 'landingpage') {
    return (
      <ImageGalleryLandingpage
        imagesPerRow={imagesPerRow}
        imgResolutionsPicture={imgResolutionsPicture}
        sectionId={sectionId}
        border={border}
        title={title}
        subtitle={subtitle}
        isChild={isChild}
        isSectionReference={isSectionReference}
        imageGallery={imageGallery}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        maxLength={maxLength}
        setMaxLength={setMaxLength}
        currentItemIndex={currentItemIndex}
        setCurrentItemIndex={setCurrentItemIndex}
        catalogName={catalogName}
      />
    );
  }

  return (
    <ImageGalleryDefault
      imagesPerRow={imagesPerRow}
      imgResolutionsPicture={imgResolutionsPicture}
      sectionId={sectionId}
      border={border}
      title={title}
      subtitle={subtitle}
      isChild={isChild}
      isSectionReference={isSectionReference}
      imageGallery={imageGallery}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      catalogName={catalogName}
      maxLength={maxLength}
      setMaxLength={setMaxLength}
      currentItemIndex={currentItemIndex}
      setCurrentItemIndex={setCurrentItemIndex}
    />
  );
}
